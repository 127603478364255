import { URL_BASE } from "../settings/global";
import { dispatchNotification } from "../layouts/app/components/notificationSystem/notifications";
import isEqual from "lodash.isequal";

export function getCookie(cname) {

    if (!process.browser) return;

    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function setCookie(cname, cvalue) {

    if (!process.browser) return;

    if (cname === 'allowCookies') {
        document.cookie = cname + "=" + cvalue + ";" + ";path=/";
    } else {
        let d = new Date();
        let minutes = 240;
        d.setTime(d.getTime() + (minutes * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
}

export function generateRandomID() {
    return Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36);
}

export function scrollToAnchor(anchor, e, smooth, container) {

    if (!!e && !!e.stopPropagation) e.stopPropagation();
    if (!!e && !!e.preventDefault) e.preventDefault();

    const element = document.getElementById(anchor) || document.getElementsByClassName(anchor)[0];
    const _container = !!container ? document.getElementById(container) : undefined;
    const layout = _container || window;

    if (!element) return false;
    const position = element.getBoundingClientRect().top;
    const windowPosition = document.body.getBoundingClientRect().top;
    const elementStart = position - windowPosition - 90;

    layout.scrollTo({
        top: elementStart,
        behavior: !!smooth ? "smooth" : 'instant'
    });

    return true
}

export function toISO(date) {

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    const _date = new Date(date);

    if (!isValidDate(_date)) return 'error'

    return _date?.toISOString().split('.')[0] + "+00:00"
}

export function getImageURL(file) {
    return file ? URL_BASE + '/files/' + file : null
}

export function generateSlug(name) {
    let slug = name || 'not found';

    slug = slug.toLowerCase();
    slug = slug.split(/[^a-zA-Z0-9]+/);
    slug = slug.filter(i => !!i);
    slug = slug.join("-");

    return slug
}

export function sortStringArray(array, desc, key) {
    return array.sort((a, b) => {
        if (key) {
            if (desc) return ('' + b[key]).localeCompare(a[key]);
            return ('' + a[key]).localeCompare(b[key]);
        }
        if (desc) return ('' + b).localeCompare(a);
        return ('' + a).localeCompare(b);
    })
}

export function sortNumberArray(array, desc, key) {
    return array.sort((a, b) => {
        if (key) {
            if (desc) return b[key] - a[key];
            return a[key] - b[key];
        }
        if (desc) return b - a;
        return a - b;
    })
}

// key is for cases when item is an object with kind of "id" (code, _id, etc.)
export function immutableArraySlice(item, array, key) {
    const index = !key ? array.findIndex(i => i === item) : array.findIndex(i => i[key] === item[key]);
    return index === -1 ? array : [...array.slice(0, index), ...array.slice(index + 1)];
}

// key is for cases when item is an object with kind of "id" (code, _id, etc.)
export function immutableArrayToggleItem(item, array, key) {
    const index = !key ? array.findIndex(i => i === item) : array.findIndex(i => i[key] === item[key]);
    return index === -1
        ? [...array, ...[item]]
        : [...array.slice(0, index), ...array.slice(index + 1)];
}

// key point of immutableArrayItemUpdate is to preserve order and avoid mutation at the same time
export function immutableArrayItemUpdate(array, item, value) {
    const index = array.findIndex(i => isEqual(i, item));
    let updatedItem;
    switch (true) {
        case typeof array[index] === 'object' && Array.isArray(value):
            updatedItem = [...array[index], ...[value]];
            break;
        case typeof array[index] === 'object' && !Array.isArray(value):
            updatedItem = {...array[index], ...value};
            break;
        default:
            updatedItem = value
            break;
    }
    return [...array.slice(0, index), ...[updatedItem], ...array.slice(index + 1)]
}

export function copyToClipboard(container, message, select) {

    const element = document.getElementById(container);

    if (select) { // visually selects text in element
        const range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
    }

    if (!navigator.clipboard) {  // old browsers
        document.execCommand("copy");
    } else {
        navigator.clipboard.writeText(element.innerText).then(
            () => {
                dispatchNotification({
                    message: message?.message || 'Copied to clipboard',
                    type: message?.type || 'success',
                })
            })
                 .catch(
                     () => {
                         dispatchNotification({
                             message: 'Error copying. Possible browser incompatibility.',
                             type: 'error',
                         })
                     });
    }
}