import React, { useEffect, useState } from 'react';
import {
    NAME_PROJECT, USER_ACCESS_TOKEN_NAME
} from "../../../settings/global";
import { getCookie } from "../../../helpers/common";
import Link from 'next/link'
import styles from './Footer.module.scss'
import { SOCIAL_DATA } from "../../../settings/content/socialProviders";
import { Content } from "../../Content";
import { useRouter } from "next/router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    NAV_AGREEMENT, NAV_BRAND, NAV_CONTACT,
    NAV_COOKIE_POLICY, NAV_EXPLAINS, NAV_HOW_IT_WORKS,
    NAV_PRIVACY_POLICY, NAV_REQUEST_STACK,
    NAV_RETURN_POLICY, NAV_SIGN_UP, NAV_TEAM,
    NAV_TERMS, NAV_WHAT_IS_TRYDIRECT
} from "../../../settings/navigation";

export default function Footer({style}) {

    const router = useRouter();

    const [hideOnAuth, setHideOnAuth] = useState(false);

    useEffect(() => {
        setHideOnAuth(!!getCookie(USER_ACCESS_TOKEN_NAME)?.length);
    }, [router.isReady, router.pathname]);

    const displaySocial = () => {

        const share = ['Facebook', 'LinkedIn', 'Twitter', 'Github', 'Telegram', 'Discord', 'Slack'];

        return (
            <div className={styles['socials']}>
                {
                    SOCIAL_DATA.map((item, index) => {
                        if (share.includes(item.name)) return (
                            <a
                                style={{
                                    '--social-margin': item.margin,
                                    '--social-color': item.color[0],
                                    '--social-color-dark': item.color?.[1],
                                }}
                                className={`${styles['socials__link']}${item.name === 'Github' ? ' ' + styles['socials__github'] : ''}`}
                                href={item.link}
                                target="_blank"
                                aria-label={`Follow us on ${item.name}`}
                                title={`Follow us on ${item.name}`}
                                rel='nofollow'
                                draggable='false'
                                key={index}
                            >
                                <i className={`${styles['socials__link__logo']}`} aria-hidden="true">
                                    <FontAwesomeIcon icon={item.icon}/>
                                </i>
                            </a>
                        )
                    })
                }
            </div>
        )
    };

    const displayCommonContent = () => {

        const renderLink = (name, url, target) => {
            return (
                <Link
                    prefetch={false}
                    href={url}
                    className={styles['links__block__link']}
                    target={target || '_self'}
                >
                    {name}
                </Link>
            );
        }

        return (
            <div className={styles['links']}>
                <div className={styles['links__block']}>
                    <h2 className={styles['links__block__title']}>Customers</h2>
                    {!hideOnAuth && renderLink('Sign Up', NAV_SIGN_UP, '_blank')}
                    {renderLink('Request a stack', NAV_REQUEST_STACK)}
                </div>
                <div className={styles['links__block']}>
                    <h2 className={styles['links__block__title']}>About</h2>
                    {renderLink('How it works', NAV_HOW_IT_WORKS)}
                    {renderLink('What is TryDirect', NAV_WHAT_IS_TRYDIRECT)}
                    {renderLink('Contact us', NAV_CONTACT)}
                    {renderLink('About us', NAV_TEAM)}
                    {renderLink('Brand assets', NAV_BRAND)}
                </div>
                <div className={styles['links__block']}>
                    <h2 className={styles['links__block__title']}>Support</h2>
                    {renderLink('Explains', NAV_EXPLAINS)}
                    {renderLink('Privacy Policy', NAV_PRIVACY_POLICY)}
                    {renderLink('Cookie Policy', NAV_COOKIE_POLICY)}
                    {renderLink('Terms and Conditions', NAV_TERMS)}
                    {renderLink('User agreement', NAV_AGREEMENT)}
                    {renderLink('Return policy', NAV_RETURN_POLICY)}
                </div>
            </div>
        )
    };

    const showAttributions = () => {
        const attributions = [
            <a href="https://www.flaticon.com/authors/nikita-golubev" target='_blank' rel='nofollow'>Nikita Golubev</a>,
            <a href='https://fontawesome.com' target='_blank' rel='nofollow'>Font Awesome</a>
        ];
        const home = router?.pathname === '/';
        return (
            <div className={styles['attributions']}>
                <p>Icons powered by {home && attributions[0]}{home && ' and '}{attributions[1]}</p>
            </div>
        )
    }

    const showProjectInfo = () => {
        return (
            <div className={styles['project']}>
                <p>{NAME_PROJECT + ' ' + new Date().getFullYear()}</p>
                <p>&#9400; All rights reserved</p>
            </div>
        )
    }

    return (
        <footer className={styles.footer} style={style ? style : {}}>
            <Content className={styles.footer__social} noSection>{displaySocial()}</Content>
            <Content className={styles.footer__links} noSection>{displayCommonContent()}</Content>
            <Content className={styles.footer__separator} stretch noSection/>
            <Content className={styles.footer__project} noSection>{showProjectInfo()}</Content>
            <Content className={styles.footer__attributions} noSection>{showAttributions()}</Content>
        </footer>
    );
}