//
//    ===    NAV
//
export const NAV_STACK_LIST = '/applications';
export const NAV_DASHBOARD = '/dashboard';
export const NAV_PRICING = '/pricing';
export const NAV_BLOG = '/blog';
export const NAV_EXPLAINS = '/explains';
export const NAV_SIGN_IN = '/sign-in';
export const NAV_SIGN_UP = '/sign-up';
export const NAV_LOGOUT = '/logout';
export const NAV_SIGN_UP_INFO = '/sign-up-info';
export const NAV_PROFILE = '/me';
export const NAV_CLOUDS = '/clouds';
export const NAV_REQUEST_STACK = '/request';
export const NAV_PASSWORD_RECOVER = '/password/recover';
export const NAV_PAYMENT_SUCCESS = '/thanks';
// info pages
export const NAV_HOW_IT_WORKS = '/how-it-works';
export const NAV_WHAT_IS_TRYDIRECT = '/what-is-trydirect';
export const NAV_CONTACT = '/contact-us';
export const NAV_TEAM = '/about-us';
export const NAV_BRAND = '/brand';
export const NAV_DOCKER_CHEAT_SHEET = '/docker-cheat-sheet';
// law docs
export const NAV_COOKIE_POLICY = '/cookie-policy';
export const NAV_PRIVACY_POLICY = '/privacy-policy';
export const NAV_TERMS = '/terms';
export const NAV_RETURN_POLICY = '/return-policy';
export const NAV_AGREEMENT = '/agreement';
// service pages
export const NAV_EMAIL_UNCONFIRMED = '/unconfirmed-email';
export const NAV_ACCOUNT_COMPLETE = '/account/complete';
export const NAV_ACCOUNT_RESTORE = '/account/restore';