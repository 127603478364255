import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from "./ripple/Button";

export default function Switch({
                                   style, className,
                                   name, value,
                                   title, description,
                                   disabled, disabledMessage,
                                   onChange, tabIndex,
                                   showSettings, onSettingsClick
}) {

    const [animationsIsAllowed, setAnimationsIsAllowed] = useState(false);

    useEffect(() => {
        setTimeout(() => setAnimationsIsAllowed(true), 1000)
    }, []);

    const handleClick = e => {
        e.preventDefault();
        if (!!name) {
            onChange({
                target: {
                    value: !value,
                    name: name
                }
            })
        } else {
            onChange(!value)
        }
    };

    const switchedOn = value ? ' switch-button--checked' : '';
    const classFromParent = className ? ` ${className}` : '';
    const disabledClass = disabled ? ' switch-button--disabled' : '';

    return (
        <div
            style={style}
            className={`switch-button${switchedOn}${disabledClass}${classFromParent}`}
            title={disabled && disabledMessage ? disabledMessage : undefined}
        >
            <div className='switch-button__title-container'>
                <div className='switch-button__title-container__title'>{!!title ? title : 'No title passed'}</div>
                {
                    description &&
                    <div className='switch-button__title-container__description'>{description}</div>
                }
            </div>
            {
                showSettings &&
                <div className='switch-button__settings-container'>
                    <Button
                        className='switch-button__settings-container__button'
                        onClick={onSettingsClick}
                        icon='fas fa-cog'
                        displayType='text'
                        tabIndex={disabled ? -1 : tabIndex || 0}
                        disabled={disabled}
                        visuals={{
                            colorBasic: ['var(--colorFontCommon)'],
                            // colorBasicDisabled: ['var(--colorFontLight)'],
                            colorFontNotContainedDisabled: ['var(--colorFontDisabled)']
                        }}
                    />
                </div>
            }
            <button
                className='switch-button__indicator' style={{'--not-allowed': animationsIsAllowed ? null : 'none'}}
                type='button'
                onClick={disabled ? () => {} : handleClick}
                tabIndex={disabled ? -1 : tabIndex || 0}
            >
                <div className='switch-button__indicator__background'/>
                <div className='switch-button__indicator__point'/>
            </button>
        </div>
    )
}

Switch.propTypes = {
    value: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.object,
    disabled: PropTypes.bool,
    disabledMessage: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    tabIndex: PropTypes.string,
    showSettings: PropTypes.bool,
    onSettingsClick: PropTypes.func,
};