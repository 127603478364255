import * as config from '../../settings/global'

// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
// This helper exists for passive event listener feature detection on user's browser.
// Use it if you provide touchstart, wheel and touchmove event listeners. See the docs linked above for more info.

export function supportsPassive() {

    let supportsPassive = false;

    try {
        let opts = Object.defineProperty({}, 'passive', {
            get: () => {supportsPassive = true}
        });
        window.addEventListener("testPassive", null, opts);
        window.removeEventListener("testPassive", null, opts);
    } catch (e) {
        if (config.DEBUG) console.warning('Browser do not support passive event listeners - "passive" will be set to "false"')
    }

    return supportsPassive
}