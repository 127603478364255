import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import CookiesAccept from "./components/CookiesAccept";
import styles from './App.module.scss'
import NotificationCenter from "./components/notificationSystem/NotificationCenter";
import DevControls from "./components/devContols/DevControls";
import Footer from "./components/Footer";
import ModalLayout from "./components/ModalLayout";
import SeoDebugger from "./components/SeoDebugger";
import { DEBUG } from "../../settings/global";
import UiHandler from "./components/UiHandler";
import Header from "./components/header/Header";
import { useRouter } from "next/router";
import { NAV_BUILDER_FLOW } from "../../settings/content/deploymentStacker";

function App({layers, dispatch, appContainerBlur, mobile, width, user, ...props}) {

    const router = useRouter();

    const [noScroll, setNoScroll] = useState(false);

    useEffect(() => {
        if (router.pathname.includes(NAV_BUILDER_FLOW)) {
            document.body.style.overflowY = 'hidden';
            document.body.style.overflowX = 'hidden';
            document.body.style.maxHeight = '100vh';
            setNoScroll(true);
        } else if (!!noScroll && !router.pathname.includes(NAV_BUILDER_FLOW)) {
            setNoScroll(false);
            document.body.style.overflowY = 'scroll';
            document.body.style.overflowX = 'auto';
            document.body.style.maxHeight = '100%';
        }
    }, [router.pathname])

    useEffect(() => {
        if (!!layers?.length) {
            document.body.style.overflowY = 'hidden';
            document.body.style.maxHeight = '100vh';
        } else {
            if (!noScroll) {
                document.body.style.overflowY = 'scroll';
                document.body.style.maxHeight = '100%';
            }
        }
    }, [layers, noScroll]);

    const defineBlurStyles = () => {

        if (!layers || !layers.length) return {};

        const defaultBlur = '10px';
        const initBlurStyles = {
            '--layout-blur': appContainerBlur,
            'filter': `blur(var(--layout-blur, ${defaultBlur}))`,
            'marginRight': !mobile && !router.pathname.includes(NAV_BUILDER_FLOW) ? '10px' : '0',
        }

        return {
            headerStyles: {
                ...initBlurStyles,
                ...(!mobile && !router.pathname.includes(NAV_BUILDER_FLOW) ? {width: 'calc(100% - 10px)'} : {})
            },
            contentStyles: initBlurStyles,
            footerStyles: initBlurStyles,
        };
    }

    const {headerStyles, contentStyles, footerStyles} = defineBlurStyles();
    const noScrollStyles = noScroll ? ' ' + styles['app--noscroll'] : '';

    return (
        <UiHandler>
            <div className={`${styles.app}${noScrollStyles}`}>
                <Header
                    user={user}
                    style={headerStyles ? headerStyles : {}}
                    isMobile={mobile}
                    width={width}
                />
                <div className={styles.app__container} style={contentStyles ? contentStyles : {}}>
                    {props.children}
                </div>
                {
                    !noScroll &&
                    <Footer style={footerStyles}/>
                }
                <NotificationCenter/>
                {DEBUG && <DevControls/>}
                {DEBUG && <SeoDebugger/>}
                <CookiesAccept/>
            </div>
            <ModalLayout/>
        </UiHandler>
    )
}

const mapStateToProps = state => ({
    user: state.userData.user,
    layers: state.modal.layers,
    width: state.UI.layoutDimensions.width,
    mobile: state.UI.mobile,
    appContainerBlur: state.modal.appContainerBlur,
});

export default connect(mapStateToProps)(App);