import { CUSTOM_STACK_NAME, NAV_CUSTOM_STACK } from "../../../../settings/content/deploymentStacker";
import {
    NAV_BLOG,
    NAV_HOW_IT_WORKS,
    NAV_PRICING,
    NAV_STACK_LIST,
    NAV_WHAT_IS_TRYDIRECT,
} from "../../../../settings/navigation";

export const NAV_LINKS = [ // if "content" property was added to a simple link, this object will be rendered as error
    {
        title: 'Home', // necessary
        link: '/', // necessary as it's purpose to serve nav block
        icon: 'home'
    },
    {
        title: CUSTOM_STACK_NAME,
        link: `${NAV_STACK_LIST}${NAV_CUSTOM_STACK}`,
        icon: 'tools',
        beta: true,
    },
    {
        title: 'Stacks',
        link: NAV_STACK_LIST,
        icon: 'search',
        showIcon: true
    },
    {
        title: 'Pricing',
        link: NAV_PRICING,
        icon: 'dollar-sign'
    },
    {
        title: 'Blog',
        link: NAV_BLOG,
        icon: 'newspaper'
    },
    { // you can add as many dropdown as you want, just consider it to have content array and items with title and link
        title: 'About',
        content: [
            {
                title: 'What is TryDirect',
                link: NAV_WHAT_IS_TRYDIRECT,
                icon: 'info-circle'
            }, {
                title: 'How it works',
                link: NAV_HOW_IT_WORKS,
                icon: 'info-circle'
            }
        ]
    },
    {
        title: 'Normaliser',
        hideTitle: true,
        link: '/normaliser',
        onClick: () => {},
        icon: 'code',
        color: 'var(--colorFontLight)',
        showIcon: true,
        dev: true,
    },
];