
// DO NOT .gitignore THIS FILE !!!
// DO NOT .gitignore THIS FILE !!!
// DO NOT .gitignore THIS FILE !!!

//    ===    Global
export const DEBUG = process.env.NEXT_PUBLIC_DEBUG === 'true';
export const ENV_LOCAL = process.env.NEXT_PUBLIC_LOCAL_ENVIRONMENT === 'true';

export function bypassCORS(route){ // @todo - remove after all CORS issues is fixed with node.js/api (never)
    // You need to add the url in next.config.js (noCorsApi) as well for bypass to actually work
    if (!ENV_LOCAL) return route; // strictly for local environment
    const split = route.split(process.env.NEXT_PUBLIC_API_CORS_BYPASS_DOMAIN);
    return split.length === 2 ? split[1] : route
}
//    ===    Branding
export const NAME_PREFIX = !!DEBUG ? '[dev] ' : '';
export const NAME_PROJECT = 'TryDirect';
export const NAME_BASE = NAME_PREFIX + NAME_PROJECT;
//    ===    Domain
export const DOMAIN_BASE = process.env.NEXT_PUBLIC_URL_BASE;
export const URL_BASE = process.env.NEXT_PUBLIC_PROTOCOL + DOMAIN_BASE;
//    ===    CDN
export const CDN_ENABLED = process.env.NEXT_PUBLIC_CDN_ENABLED === 'true';
export const CDN_SUBDOMAIN = process.env.NEXT_PUBLIC_CDN_SUBDOMAIN;
//    ===    Media
export const URL_PICTURES_BASE = process.env.NEXT_PUBLIC_URL_PICTURES_BASE;
export const URL_UPLOADED_PICTURES_FOLDER = '/files';
export const URL_PICTURES_FULL = URL_PICTURES_BASE + URL_UPLOADED_PICTURES_FOLDER;
export const LAST_STATE_STORAGE_NAME = 'last_state_storage';
//    ===    Websocket
export const URL_WEBSOCKET = process.env.NEXT_PUBLIC_WEBSOCKET_PROTOCOL + process.env.NEXT_PUBLIC_URL_BASE + '/server/app/install/read/ws';
//    ===    Local storage
export const DEPLOYMENTS_LOGS_STORAGE_NAME = 'deploymentsLogs';
export const USER_ACCESS_TOKEN_NAME = 'access_token';
//    ===    Other
export const HASH = Math.random().toString(36).substring(7);