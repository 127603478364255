import React, { useEffect } from 'react'
import '../styles/global.scss';
import AppLayout from "../layouts/app/App";
import { Provider } from 'react-redux';
import { wrapper, store } from '../redux/store'
import Head from 'next/head'
import Script from "next/script";
import { CONSENT_TYPES, googlePageView } from "../helpers/analytics/googleAnalytics";
import { useRouter } from "next/router";
import { DEBUG, ENV_LOCAL, URL_BASE } from "../settings/global";
import { Open_Sans, Roboto } from "next/font/google";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { NAV_STACK_LIST } from "../settings/navigation";

export const openSans = Open_Sans({
    subsets: ['latin', 'latin-ext'],
    // weight: ['300', '400', '500', '600', '700', '800'],
    style: ['normal', 'italic'],
});

export const roboto = Roboto({
    subsets: ['latin', 'latin-ext'],
    style: ['normal', 'italic'],
    weight: ['100', '300', '400', '500', '700'],
});

function MyApp({Component, pageProps}) {

    library.add(fab, fas, far);
    const router = useRouter()

    if (!ENV_LOCAL) {
        // log page views to google analytics
        useEffect(() => {
            const handleRouteChange = (url) => {
                googlePageView(url)
            };
            //When the component is mounted, subscribe to router changes and log those page views
            router.events.on('routeChangeComplete', handleRouteChange)
            // If the component is unmounted, unsubscribe from the event with the `off` method
            return () => {
                router.events.off('routeChangeComplete', handleRouteChange)
            }
        }, [router.events])
    }

    let consentDefaults = {};
    CONSENT_TYPES.map(type => consentDefaults[type.code] = type.defaults);


    // console.log(router)

    // const seo = getPages(NAV_TEAM);
    // console.log('seo')
    // console.log(seo)

    // const fontStyles = ` ${openSans.className} ${roboto.className}`;

    return (
        <React.Fragment>
            {/* === Start of Google and other analytics === */}
            {
                <React.Fragment>
                    <style jsx global>{`
                      body {
                        --font-roboto: ${roboto.style.fontFamily};
                        --font-open-sans: ${openSans.style.fontFamily};
                      }
                    `}</style>
                    <Script
                        strategy="lazyOnload"
                        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
                        id='google-analytics-connect'
                    />
                    {
                        router.pathname !== '/' &&
                        <Script
                            strategy="lazyOnload"
                            src={`https://kit.fontawesome.com/43d4bda97b.js`}
                            id='font-awesome-cdn'
                            crossOrigin="anonymous"
                        />
                    }
                    <Script strategy="lazyOnload" id='google-analytics-init'>
                        {`
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('consent', 'default', ${JSON.stringify(consentDefaults)});
                            gtag('js', new Date());
                            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                            page_path: window.location.pathname,
                            });
                        `}
                    </Script>
                    {
                        !DEBUG && router.pathname === NAV_STACK_LIST &&
                        <Script strategy="lazyOnload" id='Hotjar-init'>
                            {` 
                            (function(h,o,t,j,a,r){
                                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                h._hjSettings={hjid:5042986,hjsv:6};
                                a=o.getElementsByTagName('head')[0];
                                r=o.createElement('script');r.async=1;
                                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                a.appendChild(r);
                            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                            `}
                        </Script>
                    }
                </React.Fragment>
            }
            {/* /== End of Google and other analytics === */}
            {/* === Start meta tags (including common seo) === */}
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1"
                />
                <link rel="canonical" href={URL_BASE + router.pathname} key='canon'/>
                <meta name="robots" content="noindex, nofollow, noarchive, nocache, noimageindex" key='robots'/>
            </Head>
            {/* /== End of meta tags === */}
            <Provider store={store}>
                <AppLayout>
                    <Component {...{blah: 'blah'}} {...pageProps} />
                </AppLayout>
            </Provider>
        </React.Fragment>
    );
}

export default wrapper.withRedux(MyApp);
